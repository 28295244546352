import React from 'react';
import Meny1 from '../../assets/backebolmeny/meny1.jpg';
import { Helmet } from 'react-helmet';

const Backebol = () => {
  return (
    <div style={{ backgroundColor: '#324C41', height: '90vh' }}>
      <Helmet>
        <title>Beyti Kolgrill Bäckebol</title>
      </Helmet>
      <img style={{ width: '100%', height: 'auto' }} src={Meny1} alt="meny1" />
    </div>
  );
};

export default Backebol;
