import React from 'react';
import { Grid, useMediaQuery, Typography, Button } from '@material-ui/core';
import Iframe from 'react-iframe';
import { useHistory } from 'react-router-dom';

const BackebolMap = () => {
  const isMobile = useMediaQuery('(min-width:991px)');
  const history = useHistory();

  const toContact = () => {
    history.push('/kontakt');
  };

  return (
    <Grid>
      <Grid
        style={{
          display: 'flex',
          flexWrap: isMobile ? 'nowrap' : 'wrap',
          flexDirection: isMobile ? 'row' : 'column-reverse',
        }}
      >
        <Grid style={{ height: 'auto', width: '100%' }}>
          <Iframe
            url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2127.8987206688275!2d11.997697016009779!3d57.76912178114038!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464ff5068f5b7fb5%3A0x20ca6042655cf157!2sBeyti%20kolgrill%20G%C3%B6teborg!5e0!3m2!1ssv!2sse!4v1648314202126!5m2!1ssv!2sse"
            width="100%"
            height="470px"
            loading="lazy"
            frameBorder={0}
          />
        </Grid>
        <Grid
          style={{
            backgroundColor: '#484848',
            height: 'auto',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Grid>
            <Typography
              variant="h4"
              style={{
                color: '#C7914E',
                marginTop: '1em',
                fontFamily: 'Norican',
              }}
              align="center"
            >
              Beyti Kolgrill Bäckebol
            </Typography>
          </Grid>
          <Grid
            style={{
              marginTop: '2em',
              color: 'white',
              paddingLeft: isMobile ? '' : '15px',
            }}
          >
            <Typography variant="h6">
              <span style={{ color: '#C7914E' }}>Tel:</span> 031 - 13 12 33
            </Typography>
            <Typography variant="h6">
              <span style={{ color: '#C7914E' }}>E-post:</span>{' '}
              kontakt@beytikolgrill.se
            </Typography>
            <Typography variant="h6">
              <span style={{ color: '#C7914E' }}>Adress:</span> Transportgatan
              19, 442 46 Hisings Backa
            </Typography>
          </Grid>
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '5em',
              marginBottom: '5em',
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              style={{ backgroundColor: '#C7914E' }}
              onClick={toContact}
            >
              Kontakta Oss
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BackebolMap;
