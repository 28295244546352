import React from 'react';
import { Button, Grid, useMediaQuery } from '@material-ui/core';
import Overby from '../../assets/överby.jpeg';
import Backebol from '../../assets/backebol.jpeg';
import Falkenberg from '../../assets/falkenberg.jpeg';
import { useHistory } from 'react-router-dom';

const Meny = () => {
  const isMobile = useMediaQuery('(min-width:767px)');
  const history = useHistory();

  const toMenyOverby = () => {
    history.push('/overby-meny');
  };
  const toMenyBackebol = () => {
    history.push('/backebol-meny');
  };
  const toMenyFalkenberg = () => {
    history.push('/falkenberg-meny');
  };
  return (
    <Grid style={{ backgroundColor: '#484848' }}>
      <Grid
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: isMobile ? 'normal' : 'center',
          flexDirection: isMobile ? 'row' : 'column',
        }}
      >
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <img
            src={Overby}
            alt="overby"
            style={{
              height: '300px',
              width: '300px',
              borderRadius: '2%',
              margin: '1em',
            }}
          />
          <Button
            variant="contained"
            color="primary"
            style={{
              backgroundColor: '#C7914E',
              width: '300px',
              margin: '1em',
            }}
            onClick={toMenyOverby}
          >
            Överby
          </Button>
        </Grid>
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <img
            src={Backebol}
            alt="Backebol"
            style={{
              height: '300px',
              width: '300px',
              borderRadius: '2%',
              margin: '1em',
            }}
          />
          <Button
            variant="contained"
            color="primary"
            style={{
              backgroundColor: '#C7914E',
              width: '300px',
              margin: '1em',
            }}
            onClick={toMenyBackebol}
          >
            Bäckebol
          </Button>
          <img
            src={Falkenberg}
            alt="Falkenberg"
            style={{
              height: '300px',
              width: '300px',
              borderRadius: '2%',
              margin: '1em',
            }}
          />
          <Button
            variant="contained"
            color="primary"
            style={{
              backgroundColor: '#C7914E',
              width: '300px',
              margin: '1em',
            }}
            onClick={toMenyFalkenberg}
          >
            Falkenberg
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Meny;
