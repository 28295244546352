import React from 'react';
import Iframe from 'react-iframe';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import Link from '@mui/material/Link';

const Facebook = () => {
  const isMobile = useMediaQuery('(min-width:991px)');

  return (
    <Grid
      style={{ display: 'flex', flexDirection: isMobile ? 'row' : 'column' }}
    >
      <Grid
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#484848',
          width: '100%',
        }}
      >
        <Iframe
          width="340"
          height="600"
          scrolling="no"
          frameBorder={0}
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          url="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fbeytikolgrill&tabs=timeline&width=340&height=600&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
        ></Iframe>
      </Grid>
      <Grid
        style={{
          width: '100%',
          backgroundColor: '#C7914E',
          height: '600px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h6"
          style={{ color: 'white', fontWeight: 'bolder' }}
        >
          Glöm inte att följa oss på sociala medier!
        </Typography>
        <Typography
          variant="body1"
          align="center"
          style={{ fontSize: '15px', padding: '2em' }}
        >
          Gå gärna in och följ oss på Facebook och Instagram för att se mer utav
          Beyti Kolgrill. Klicka på bilderna nedan för att komma till våra
          sidor.
        </Typography>
        <Grid
          style={{
            width: '100%',
            backgroundColor: '#C7914E',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Link href="https://www.facebook.com/beytikolgrill/">
            <FacebookIcon
              style={{ color: '#484848', padding: '10px', fontSize: '100px' }}
            />
          </Link>
          <Link href="https://www.instagram.com/beytikolgrill/">
            <InstagramIcon
              style={{ color: '#484848', padding: '10px', fontSize: '100px' }}
            />
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Facebook;
