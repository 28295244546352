import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  infoBox: {
    width: '100%',
  },
  title: {
    color: '#484848',
    fontFamily: 'Norican',
    fontSize: '40px',
    fontWeight: 'bold',
  },
  slogan: {
    color: 'white',
    fontSize: '53px',
    lineHeight: '60px',
    letterSpacing: '-.25px',
    fontWeight: 'bold',
    marginTop: '0.5em',
  },
  text: {
    color: 'white',
    marginTop: '0.5em',
    marginRight: '1em',
  },
  button: {
    backgroundColor: '#484848',
    fontFamily: 'Artifika',
    fontWeight: 'bold',
    width: '10em',
    height: '4em',
    marginTop: '1em',
  },
  img: {
    width: '100%',
    borderRadius: '10px',
    marginTop: '2em',
  },
}));
