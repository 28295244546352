import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, Grid, useMediaQuery } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';

const Contact = () => {
  const isMobile = useMediaQuery('(min-width:1024px)');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_k0lukn3',
        'template_tj0m71a',
        form.current,
        'user_4rpsxYDJhwxIZ7zYReNVt'
      )
      .then(
        (result) => {
          //console.log(result.text);
        },
        (error) => {
          //console.log(error.text);
        }
      );
  };

  const form = useRef();
  return (
    <Box component="form" ref={form} onSubmit={sendEmail}>
      <Grid
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: isMobile ? '25em' : '0',
          paddingRight: isMobile ? '25em' : '0',
          paddingTop: isMobile ? '5em' : '0',
          height: '100vh',
        }}
      >
        <TextField
          id="outlined-basic"
          label="Namn"
          variant="outlined"
          name="user_name"
          style={{
            margin: '1em',
          }}
        />
        <TextField
          id="outlined-basic"
          label="E-post"
          variant="outlined"
          name="user_email"
          style={{ margin: '1em' }}
        />
        <TextField
          id="outlined-basic"
          label="Telefonnummer"
          variant="outlined"
          name="user_phone"
          style={{ margin: '1em' }}
        />
        <TextField
          id="outlined-basic"
          label=""
          variant="outlined"
          type="date"
          name="date"
          style={{ margin: '1em' }}
        />
        <TextareaAutosize
          minRows={10}
          id="outlined-basic"
          name="message"
          style={{ margin: '1em' }}
        />

        <Button
          type="submit"
          value="Send"
          variant="contained"
          color="primary"
          style={{ margin: '1em', backgroundColor: '#C7914E' }}
        >
          Skicka
        </Button>
      </Grid>
    </Box>
  );
};

export default Contact;
