import React from 'react';
import { Route, Switch } from 'react-router-dom';
//import About from '../pages/about';
import Home from '../pages/home';
import Backebol from '../pages/restaurant/backebol';
import Overby from '../pages/restaurant/overby';
import Falkenberg from '../pages/restaurant/falkenberg';
import MenyBackebol from '../pages/meny/meny-backebol';
import MenyOverby from '../pages/meny/meny-overby';
import Contact from '../pages/contact';
import Meny from '../pages/meny/meny';
import MenyFalkenberg from '../pages/meny/meny-falkenberg';

export default function App() {
  return (
    <Switch>
      <Route exact path="/" render={(props: any) => <Home {...props} />} />
      <Route exact path="/meny" render={(props: any) => <Meny {...props} />} />
      {/* <Route
        exact
        path="/omoss"
        render={(props: any) => <About {...props} />}
      /> */}
      <Route
        exact
        path="/backebol"
        render={(props: any) => <Backebol {...props} />}
      />
      <Route
        exact
        path="/overby"
        render={(props: any) => <Overby {...props} />}
      />
      <Route
        exact
        path="/falkenberg"
        render={(props: any) => <Falkenberg {...props} />}
      />
      <Route
        exact
        path="/backebol-meny"
        render={(props: any) => <MenyBackebol {...props} />}
      />
      <Route
        exact
        path="/overby-meny"
        render={(props: any) => <MenyOverby {...props} />}
      />
      <Route
        exact
        path="/falkenberg-meny"
        render={(props: any) => <MenyFalkenberg {...props} />}
      />
      <Route
        exact
        path="/kontakt"
        render={(props: any) => <Contact {...props} />}
      />
    </Switch>
  );
}
