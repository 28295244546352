import React from 'react';
import { Grid, Typography, Button, useMediaQuery } from '@material-ui/core';
import Food2 from '../../assets/food2.jpeg';
import useStyles from './styles';
import { useHistory } from 'react-router-dom';

const Foods = () => {
  const classes = useStyles();
  const history = useHistory();
  const isMobile = useMediaQuery('(min-width:767px)');

  const toMeny = () => {
    history.push('/meny');
  };

  const OnMouseOverImageEffect = (e: any) => {
    e.target.style.filter = 'blur(5px)';
  };
  const OnMouseOutImageEffect = (e: any) => {
    e.target.style.filter = 'blur(0px)';
  };
  return (
    <Grid
      style={{
        backgroundColor: '#C7914E',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Grid
        style={{
          width: '70%',
          display: 'flex',
          paddingTop: '5em',
          paddingBottom: '5em',
          flexWrap: isMobile ? 'nowrap' : 'wrap',
        }}
      >
        <Grid className={classes.infoBox}>
          <Typography variant="h4" className={classes.title}>
            Kebab betyder grillat kött…
          </Typography>
          <Typography variant="h2" className={classes.slogan}>
            Klassisk Kebab
          </Typography>
          <br />
          <Typography variant="h5" className={classes.text}>
            Turkisk kolgrill, Pide, Kebab & Meze...{' '}
          </Typography>
          <br />
          <Typography className={classes.text}>
            Det är alltså inte bara lammskav som är kebab. Det är all sorts
            grillat kött som tillagas och serveras på ett visst sätt. Köttet är
            pressat till en cylinder som grillas roterande i en kebabmaskin. När
            köttet är färdigt skärs tunna remsor från cylindern för att ätas med
            olika tillbehör.
          </Typography>
          <br />
          <br />
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={toMeny}
          >
            MENY
          </Button>
        </Grid>

        <Grid style={{ width: '100%' }}>
          <img
            onMouseOver={OnMouseOverImageEffect}
            onMouseOut={OnMouseOutImageEffect}
            src={Food2}
            className={classes.img}
            alt="foodimg1"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Foods;
