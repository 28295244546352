import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from '../../assets/logo.jpeg';
import menuItems from './menu-items';
import useStyles from './styles';
import {
  Grid,
  Button,
  IconButton,
  Typography,
  Toolbar,
  AppBar,
  MenuItem,
  useMediaQuery,
  Menu,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Fade from '@mui/material/Fade';

const Header = (props: any) => {
  const { history } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [dropDownMeny, setDropDownMeny] = React.useState<null | HTMLElement>(
    null
  );
  const [dropDownRestaurang, setDropRestaurang] =
    React.useState<null | HTMLElement>(null);
  const openDropDownMeny = Boolean(dropDownMeny);
  const openDropDownRestaurang = Boolean(dropDownRestaurang);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setDropDownMeny(event.currentTarget);
  };
  const handleClose = () => {
    setDropDownMeny(null);
  };

  const handleClickRestararang = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setDropRestaurang(event.currentTarget);
  };

  const handleCloseRestaurang = () => {
    setDropRestaurang(null);
  };

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClick = (pageURL: any) => {
    history.push(pageURL);
    setAnchorEl(null);
  };

  const handleButtonClick = (pageURL: any) => {
    history.push(pageURL);
  };

  return (
    <Grid>
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <Typography
            variant="h6"
            className={classes.title}
            onClick={() => handleButtonClick('/')}
          >
            <img className={classes.logo} src={Logo} alt="logo" />
          </Typography>
          {isMobile ? (
            <>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={handleMenu}
              >
                <MenuIcon className={classes.menuIcon} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={() => setAnchorEl(null)}
              >
                {menuItems.map((menuItem) => {
                  const { menuTitle, pageURL } = menuItem;
                  return (
                    <MenuItem onClick={() => handleMenuClick(pageURL)}>
                      {menuTitle}
                    </MenuItem>
                  );
                })}
                <Button
                  id="fade-button"
                  aria-controls={openDropDownMeny ? 'fade-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openDropDownMeny ? 'true' : undefined}
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDownIcon />}
                  style={{ marginLeft: '8px' }}
                >
                  Menyer
                </Button>
                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    'aria-labelledby': 'fade-button',
                  }}
                  anchorEl={dropDownMeny}
                  open={openDropDownMeny}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  <MenuItem onClick={() => handleButtonClick('/backebol-meny')}>
                    Beyti Bäckebol
                  </MenuItem>
                  <MenuItem onClick={() => handleButtonClick('/overby-meny')}>
                    Beyti Överby
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleButtonClick('/falkenberg-meny')}
                  >
                    Beyti Falkenberg
                  </MenuItem>
                </Menu>
                <Button
                  id="fade-button"
                  aria-controls={dropDownRestaurang ? 'fade-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={dropDownRestaurang ? 'true' : undefined}
                  onClick={handleClickRestararang}
                  endIcon={<KeyboardArrowDownIcon />}
                  style={{ marginLeft: '8px' }}
                >
                  Restauranger
                </Button>
                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    'aria-labelledby': 'fade-button',
                  }}
                  anchorEl={dropDownRestaurang}
                  open={openDropDownRestaurang}
                  onClose={handleCloseRestaurang}
                  TransitionComponent={Fade}
                >
                  <MenuItem onClick={() => handleButtonClick('/backebol')}>
                    Bäckebol
                  </MenuItem>
                  <MenuItem onClick={() => handleButtonClick('/overby')}>
                    Överby
                  </MenuItem>
                  <MenuItem onClick={() => handleButtonClick('/falkenberg')}>
                    Falkenberg
                  </MenuItem>
                </Menu>
              </Menu>
            </>
          ) : (
            <Grid className={classes.headerOptions}>
              {menuItems.map((menuItem) => {
                const { menuTitle, pageURL } = menuItem;
                return (
                  <Button
                    onClick={() => handleButtonClick(pageURL)}
                    className={classes.button}
                  >
                    {menuTitle}
                  </Button>
                );
              })}
              <Button
                id="fade-button"
                aria-controls={openDropDownMeny ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openDropDownMeny ? 'true' : undefined}
                onClick={handleClick}
                className={classes.button}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Menyer
              </Button>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  'aria-labelledby': 'fade-button',
                }}
                anchorEl={dropDownMeny}
                open={openDropDownMeny}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                <MenuItem onClick={() => handleButtonClick('/backebol-meny')}>
                  Beyti Bäckebol
                </MenuItem>
                <MenuItem onClick={() => handleButtonClick('/overby-meny')}>
                  Beyti Överby
                </MenuItem>
                <MenuItem onClick={() => handleButtonClick('/falkenberg-meny')}>
                  Beyti Falkenberg
                </MenuItem>
              </Menu>

              <Button
                id="fade-button"
                aria-controls={dropDownRestaurang ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={dropDownRestaurang ? 'true' : undefined}
                onClick={handleClickRestararang}
                className={classes.button}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Restauranger
              </Button>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  'aria-labelledby': 'fade-button',
                }}
                anchorEl={dropDownRestaurang}
                open={openDropDownRestaurang}
                onClose={handleCloseRestaurang}
                TransitionComponent={Fade}
              >
                <MenuItem onClick={() => handleButtonClick('/backebol')}>
                  Bäckebol
                </MenuItem>
                <MenuItem onClick={() => handleButtonClick('/overby')}>
                  Överby
                </MenuItem>
                <MenuItem onClick={() => handleButtonClick('/falkenberg')}>
                  Falkenberg
                </MenuItem>
              </Menu>
            </Grid>
          )}
        </Toolbar>
      </AppBar>
    </Grid>
  );
};

export default withRouter(Header);
