import React from 'react';
import ReactPlayer from 'react-player';
import heroVideo from '../../assets/backgroundVideo.mp4';
import { Grid, useMediaQuery } from '@material-ui/core';
//import useStyles from './styles';
import RestaurantInfo from '../../components/restaurant-info';
import ZoomSlide from '../../components/ZoomSlider/ZoomSlider';
import Foods from '../../components/foods';
import { Helmet } from 'react-helmet';
import BackebolMap from '../../components/restaurant-map/backebol';
import OverbyMap from '../../components/restaurant-map/overby';
import FalkenbergMap from '../../components/restaurant-map/falkenberg';
import Facebook from '../../components/social-media/facebook';
import Footer from '../../components/footer';

const Home = () => {
  const isMobile = useMediaQuery('(min-width:991px)');

  //const classes = useStyles();
  return (
    <Grid>
      <Helmet>
        <title>Beyti Kolgrill & Meze</title>
      </Helmet>
      {isMobile ? (
        <ReactPlayer
          url={heroVideo}
          playing
          loop
          muted
          controls={false}
          height="auto"
          width="100%"
        />
      ) : (
        <ZoomSlide />
      )}
      <RestaurantInfo />
      <Foods />
      <BackebolMap />
      <OverbyMap />
      <FalkenbergMap />
      <Facebook />
      <Footer />
    </Grid>
  );
};

export default Home;
