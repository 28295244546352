import image1 from '../../assets/slideImages/image1.jpg';
import image2 from '../../assets/slideImages/image2.jpg';
import image3 from '../../assets/slideImages/image3.jpg';
import image4 from '../../assets/slideImages/image4.jpg';

const imageList = [
  {
    menuTitle: image1,
  },
  {
    menuTitle: image2,
  },
  {
    menuTitle: image3,
  },
  {
    menuTitle: image4,
  },
];

export default imageList;
