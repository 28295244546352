import React from 'react';
import Meny1 from '../../assets/falkenbergmeny/meny1.jpg';
import Meny2 from '../../assets/falkenbergmeny/meny2.jpg';
import Meny3 from '../../assets/falkenbergmeny/meny3.jpg';
import Meny4 from '../../assets/falkenbergmeny/meny4.jpg';
import Meny5 from '../../assets/falkenbergmeny/meny5.jpg';
import Meny6 from '../../assets/falkenbergmeny/meny6.jpg';
import Meny7 from '../../assets/falkenbergmeny/meny7.jpg';
import Meny8 from '../../assets/falkenbergmeny/meny8.jpg';
import { Helmet } from 'react-helmet';

const MenyFalkenberg = () => {
  return (
    <div>
      <Helmet>
        <title>Beyti Kolgrill Falkenberg Meny</title>
      </Helmet>
      <img
        src={Meny1}
        alt="meny1"
        style={{ width: '100%', marginTop: '-5px' }}
      />
      <img
        src={Meny2}
        alt="meny2"
        style={{ width: '100%', marginTop: '-5px' }}
      />
      <img
        src={Meny3}
        alt="meny3"
        style={{ width: '100%', marginTop: '-5px' }}
      />
      <img
        src={Meny4}
        alt="meny4"
        style={{ width: '100%', marginTop: '-5px' }}
      />
      <img
        src={Meny5}
        alt="meny5"
        style={{ width: '100%', marginTop: '-5px' }}
      />
      <img
        src={Meny6}
        alt="meny6"
        style={{ width: '100%', marginTop: '-5px' }}
      />
      <img
        src={Meny7}
        alt="meny7"
        style={{ width: '100%', marginTop: '-5px' }}
      />
      <img
        src={Meny8}
        alt="meny8"
        style={{ width: '100%', marginTop: '-5px' }}
      />
    </div>
  );
};

export default MenyFalkenberg;
