const menuItems = [
  {
    menuTitle: 'Hem',
    pageURL: '/',
  },
  // {
  //   menuTitle: 'Om oss',
  //   pageURL: '/omoss',
  // },
  // {
  //   menuTitle: 'Restauranger',
  //   pageURL: '/restauranger',
  // },
  // {
  //   menuTitle: 'Menyer',
  //   pageURL: '/menyer',
  // },
  {
    menuTitle: 'Kontakt',
    pageURL: '/kontakt',
  },
];

export default menuItems;
