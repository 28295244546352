import React from 'react';
import { Grid, useMediaQuery, Typography, Button } from '@material-ui/core';
import Iframe from 'react-iframe';
import { useHistory } from 'react-router-dom';

const FalkenbergMap = () => {
  const isMobile = useMediaQuery('(min-width:991px)');
  const history = useHistory();

  const toContact = () => {
    history.push('/kontakt');
  };

  return (
    <Grid>
      <Grid
        style={{
          display: 'flex',
          flexWrap: isMobile ? 'nowrap' : 'wrap',
          flexDirection: isMobile ? 'row' : 'column-reverse',
        }}
      >
        <Grid style={{ height: 'auto', width: '100%' }}>
          <Iframe
            url="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d8714.207018723397!2d12.4907411!3d56.9050715!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdafee9772b32faf6!2sBeyti%20kolgrill%20Falkenberg!5e0!3m2!1ssv!2sse!4v1667945488074!5m2!1ssv!2sse"
            width="100%"
            height="470px"
            loading="lazy"
            frameBorder={0}
          />
        </Grid>
        <Grid
          style={{
            backgroundColor: '#484848',
            height: 'auto',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Grid>
            <Typography
              variant="h4"
              style={{
                color: '#C7914E',
                marginTop: '1em',
                fontFamily: 'Norican',
              }}
              align="center"
            >
              Beyti Kolgrill Falkenberg
            </Typography>
          </Grid>
          <Grid
            style={{
              marginTop: '2em',
              color: 'white',
              paddingLeft: isMobile ? '' : '15px',
            }}
          >
            <Typography variant="h6">
              <span style={{ color: '#C7914E' }}>Tel:</span> 0346 - 121 50
            </Typography>
            <Typography variant="h6">
              <span style={{ color: '#C7914E' }}>E-post:</span>{' '}
              kontakt@beytikolgrill.se
            </Typography>
            <Typography variant="h6">
              <span style={{ color: '#C7914E' }}>Adress:</span> Arvidstorpvägen
              2, 311 31 Falkenberg
            </Typography>
          </Grid>
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '5em',
              marginBottom: '5em',
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              style={{ backgroundColor: '#C7914E' }}
              onClick={toContact}
            >
              Kontakta Oss
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FalkenbergMap;
