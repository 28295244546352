import React from 'react';
import { Grid, Typography } from '@material-ui/core';

const Footer = () => {
  return (
    <Grid
      style={{
        height: '5vh',
        backgroundColor: '#484848',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography style={{ color: 'white', fontWeight: 'bolder' }}>
        &copy; Beyti Kolgrill
      </Typography>
    </Grid>
  );
};

export default Footer;
